
import './css/main.css';

import barba from '@barba/core';

import { gsap } from "gsap";

import { defineCustomElements } from 'vidstack/elements';


barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        menuHighlight(data);
        galleryCheck();
        // openGallery();
        closeGallery();
        setTimeout(function(){defineCustomElements();vidCheck();}, 200);
        clientHoverCheck();
        aboutHoverCheck();
        clientHoverCheck2();
        logoSwap();
      },
      afterOnce(data) {
      }
    }],
});



function vidCheck() {
    let vidPlayers = document.querySelectorAll('media-player');
    if (vidPlayers.length > 0) {
        let vd;
        for (vd=0; vd < vidPlayers.length; vd++) {
            vidPlayers[vd].addEventListener('provider-change', (event) => {
                const provider = event.detail;
                if (provider?.type === 'hls') {
                    console.log('HLS config change');
                    provider.library = () => import('hls.js');
                    provider.config = { startLevel: 7 };
                };
            });
  
  
            vidPlayers[vd].addEventListener('hls-level-switching', (event) => {
              // console.log(event.detail)
            });
        };
    };
  };


  function galleryCheck() {
    let galleries = document.getElementsByClassName('gallery');
    if (galleries.length > 0) {
      import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
        const galleryGo = module.default;
        galleryGo();
      });
    };
  };

  // window.addEventListener('hashChange', function(e) {
  //   console.log('HashChange');
  // });

  // function openGallery() {
  //   let gal;
  //   let clickers = document.getElementsByClassName('openGallery');
  //   if(clickers.length > 0) {
  //     for (gal=0; gal < clickers.length; gal++ ) {
  //       clickers[gal].addEventListener('click', function (event) {
  //         let gallery = document.getElementsByClassName('rollingGallery')[0];
  //         gallery.classList.remove('hidden');
  //         let hash = event.target.closest('.openGallery').getAttribute('data-hash');
  //         // console.log(hash);
  //         window.location.hash = hash
  //       })
  //     }
  //   }
  // }

  function closeGallery() {
    let gal;
    let clickers = document.getElementsByClassName('closeGallery');
    if(clickers.length > 0) {
      for (gal=0; gal < clickers.length; gal++ ) {
        clickers[gal].addEventListener('click', function () {
          let gallery = document.getElementsByClassName('rollingGallery')[0];
          gallery.classList.add('hidden');
          // window.location.hash = '-';
          history.replaceState(null, null, ' ');
        })
      }
    }
  }

  function menuHighlight(data) {
    import(/* webpackChunkName: "menuHighlight" */ "./js/components/menuHighlight.js").then(module => {
      const highlightGo = module.default;
      highlightGo(data);
    });
  }

  function clientHoverCheck() {
    let clients = document.getElementsByClassName('clientName');
    if (clients.length > 0) {
      import(/* webpackChunkName: "clientHover" */ "./js/components/clientHover.js").then(module => {
        const clientGo = module.default;
        clientGo();
      });
    }
  }

  function aboutHoverCheck() {
    let about = document.getElementsByClassName('aboutImages');
    if (about.length > 0) {
      import(/* webpackChunkName: "clientHover" */ "./js/components/aboutHover.js").then(module => {
        const aboutGo = module.default;
        aboutGo();
      });
    }
  }

  function clientHoverCheck2() {
    let clients2 = document.getElementsByClassName('clientName2');
    if (clients2.length > 0) {
      import(/* webpackChunkName: "clientHover" */ "./js/components/clientHover2.js").then(module => {
        const clientGo2 = module.default;
        clientGo2();
      });
    }
  }

  function logoSwap() {
    import(/* webpackChunkName: "logoSwap" */ "./js/components/logoSwap.js").then(module => {
      const swapGo = module.default;
      swapGo();
    });
  }

  function hamburgerToggle(preset) {
    import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
        const toggle = module.default;
        toggle(preset);
    });
}

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});

let menuCloser = document.getElementById('dropdownCloser');
menuCloser.addEventListener("click", () => {hamburgerToggle('c')});

  function scrollToTop() {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    window.scrollTo({top: 0});
  };

  barba.hooks.beforeEnter((data) => {
    scrollToTop();
  });

  barba.hooks.before((data) => {
    hamburgerToggle('c');
})


  barba.hooks.after((data) => {
    menuHighlight(data);
    galleryCheck();
    // openGallery();
    closeGallery();
    setTimeout(function(){defineCustomElements();vidCheck();}, 200);
    clientHoverCheck();
    aboutHoverCheck();
    clientHoverCheck2();
  });